import * as React from 'react';
import Spinner from '../../React/Misc/Spinner';
import MasterContext from '../../React/Misc/MasterContext';
import { RouteComponentProps, Prompt } from 'react-router-dom';

export interface IProps {}

export interface IState {
    loaded: boolean;
    dirty: boolean;
    defaultView: boolean;
}

export default class PriceReviewFramed extends React.Component<RouteComponentProps<any>, IState> {

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { loaded: true, dirty: false, defaultView: false }
        
    }
    componentDidMount() {
        this.checkPriceReviewDefaultView().catch(err => console.log(err.message));

        window.onmessage = (e) => {
            if (e.data === 'childDidLoad') {
                this.setState({ loaded: false });
            }

            // if iframed angular view has unsaved changes or `is dirty`
            // alert the user to save with <Prompt /> in component jsx
            if (e.data === 'dirtyTrue') {
                this.setState({ dirty: true });
            }
            if (e.data === 'dirtyFalse') {
                this.setState({ dirty: false });
            }
        };
    }

    componentWillUnmount() {
        window.onmessage = (e) => {}

        // // to set view back to default when navigating away from Price Review use below
        // // we will clear it on login instead
        // localStorage.setItem('PriceReviewGridOptions', '0')
    }

    getURL(): string {
        let retVal = '';
        var token = localStorage.getItem("userToken");
        if (token != null) {
            retVal = MasterContext.LEGACYURL + "indexPR.html?apiKey=" + token + "#/priceReviewNew";
        } else {
            retVal = MasterContext.LEGACYURL + "indexPR.html#/priceReviewNew";
        }

        return retVal;
    }

    async checkPriceReviewDefaultView() {
        const url = `${MasterContext.REACTURL}/PricingEngineService/PriceReviews/getPriceReviewDefaultView`;
        const response = await fetch(url, {
            headers: { apiKey: localStorage.getItem('userToken') },
        });

        if (!response.ok) throw new Error(`An error has occurred: ${response.status}`);
        const res = await response.json();
        // if fetched client Web.config indicates they want to see default view
        if (res === 'true') {
            // update the `defaultView` state
            // to ensure rerender place a `data-default-view={defaultView}` attribute on the iframe in the jsx in our render()
            // otherwise the angular iframe code won't pick up the change to local storage
            await localStorage.setItem('PriceReviewGridOptions', '0');
            await this.setState({ defaultView: true });
        }
    }



    render() {

        const { dirty, loaded, defaultView } = this.state;

        let style1 = { height: "405px" }
        let style2 = { border: "0px", height: "97vh" };
        return (
            <div id="mainBody" className="main-body container-fill ng-scope body-fill" style={style1}>

                  <Prompt when={dirty && !defaultView} message="You have unsaved changes to your view. Are you sure you want to navigate away?" />
                
                  <Spinner
                    spinnerActive={loaded}
                    heightSet="99vh"
                    paddingSet="10%"
                    spinnerText={MasterContext.translate("spinner_loading_price_review")}
                />
                <iframe title='iframePriceReview' id="iframePriceReview" src={this.getURL()} width="100%" style={style2} data-default-view={defaultView}></iframe>
            </div>
        );

    }

}